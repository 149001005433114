import axios from "axios";

let baseUrl;

if (
  window.location.origin === "http://localhost:3000" ||
  window.location.origin === "http://localhost:3001"
) {
  baseUrl = "http://127.0.0.1:8000/";
} else {
  if (window.location.origin === "https://erp.azranbd.com" ||
    window.location.origin === "erp.azranbd.com") {
    baseUrl = "https://azran.up.railway.app/";
  } else {
    baseUrl = window.location.origin;
  }

}
export default axios.create({
  baseURL: baseUrl,
});
