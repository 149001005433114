import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Drawer, Divider, Col, Row, Button, message, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { getAllBrand } from "../../../actions/brandAction";

const Payslip = ({ details, Auth, businessprofile, getAllBrand, brandlist }) => {
  console.log(details);
  const [visible, setVisible] = useState(false);
  const componentRef = useRef();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const showDrawer = () => {
    getAllBrand()
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Link to="#" onClick={showDrawer} key={details.id}>
        Payslip
      </Link>

      <Drawer
        width="60%"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <ReactToPrint
          trigger={() => <Button type="primary">Print this out!</Button>}
          content={() => componentRef.current}
        />
        <div
          className="payslip_print_fontSize"
          ref={componentRef}
          style={{ padding: "40px" }}
        >
          {/* business profile */}
          <Row gutter={24}>
            <Col span={4}>
              <img
                src={businessprofile.logo}
                style={{ height: "60px", zIndex: "-1" }}
              />
            </Col>
            <Col span={4}></Col>
            <Col span={6}></Col>
            <Col span={10}>
              <Row gutter={24}>
                <Col span={24} style={{ fontSize: "24px", textAlign: "right" }}>
                  {brandlist[0]?.name}
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24} style={{ fontSize: "10px", textAlign: "right" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: businessprofile.address,
                    }}
                  ></div>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Employee information */}
          <div>
            <Row gutter={24}>
              <Col span={4}></Col>
              <Col span={4}></Col>
              <Col span={8} align="center" style={{ fontSize: "20px" }}>
                SALARY PAYSLIP
              </Col>
              <Col span={4}></Col>
              <Col span={4}></Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}></Col>
              <Col span={4}></Col>
              <Col span={8} align="center" style={{ fontSize: "14px" }}>
                {monthNames[details.salaryMonth - 1]}, {details.salaryYear}
              </Col>
              <Col span={4}></Col>
              <Col span={4}></Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>Name</Col>
              <Col span={6} style={{ fontWeight: "bold" }}>
                {details.employeeName}
              </Col>
              <Col span={4}></Col>
              <Col span={6} style={{ textAlign: "right" }}>
                Issue Date
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {moment(details.created).format("DD-MM-YYYY")}
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>ID</Col>
              <Col span={6}>{1000 + details.employee.id}</Col>
              <Col span={4}></Col>
              <Col span={6} style={{ textAlign: "right" }}>
                Basic Salary
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {details.current_salary}
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>Public Holiday</Col>
              <Col span={6}>{details.publicHoliday}</Col>
              <Col span={4}></Col>

              <Col span={6} style={{ textAlign: "right" }}>
                Daily Allowance
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {details.current_allowance}
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>Designation</Col>
              <Col span={6}>{details.employee.user_roleName}</Col>
              <Col span={4}></Col>

              <Col span={6} style={{ textAlign: "right" }}>
                Day Wage/Day
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {details.current_salary_day}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}>Phone</Col>
              <Col span={6}>{details.employee.phone}</Col>
              <Col span={4}></Col>
              <Col span={6} style={{ textAlign: "right" }}>
                Day Wage/Hour
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {details.current_salary_day_hour}
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}>Address</Col>
              <Col span={6}>{details.employee.address}</Col>
              <Col span={4}></Col>
              <Col span={6} style={{ textAlign: "right" }}>
                Night Wage/Day ( 5 * 1.5 * Daily Wage )
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {details.current_salary_night}
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={4}></Col>
              <Col span={6}></Col>
              <Col span={4}></Col>
              <Col span={6} style={{ textAlign: "right" }}>
                Night Wage/Hour ( 1.5 * Hourly Wage )
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {details.current_salary_night_hour}
              </Col>
            </Row>
            <br />
            <br />
          </div>

          {/* table */}
          <div>
            <Row>
              <Col span={24}>
                <table className="payslip_table">
                  <thead>
                    <tr>
                      <th colspan="2" style={{ background: "lime" }}>
                        Earnings
                      </th>
                      <th colspan="2" style={{ background: "lightsalmon" }}>
                        Deductions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Basic Salary:</td>
                      <td>{details.current_salary}</td>
                      <td>Loan Amount:</td>
                      <td>{details.current_loan}</td>
                    </tr>
                    <tr>
                      <td>Overtime - Night</td>
                      <td>{details.nightOverTimeHour}</td>
                      <td>Adjustment</td>
                      <td>
                        {details.loan_adjustment}, Rest Loan:{" "}
                        {details.current_loan - details.loan_adjustment}
                      </td>
                    </tr>

                    <tr>
                      <td>Overtime- Day</td>
                      <td>{details.dayOverTimeHour}</td>
                      <td>Fine</td>
                      <td>
                        {details.fine} (Late: {details.late}, Absent:{" "}
                        {details.absent}, Attendance: {details.attendance},
                        Leave: {details.leave})
                      </td>
                    </tr>

                    <tr>
                      <td>Overtime- Hour</td>
                      <td>{details.OverTimeHour}</td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Total Overtime Bill</td>
                      <td>{details.overtimeTotal}</td>
                      <td>Advance</td>
                      <td>{details.advance_adjustment}</td>
                    </tr>

                    <tr>
                      <td>Overtime Night Allowance</td>
                      <td>
                        {details.OvertimeNightAllowance}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Mobile Allowance</td>
                      <td>
                        {details.mobileAllowance}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Incentive</td>
                      <td>{details.incentiveTotal}</td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>Total Daily Allowances</td>
                      <td>{details.dailyAllowanceTotal}</td>
                      <td>Penalty</td>
                      <td>{details.manual_adjustment}</td>
                    </tr>

                    <tr>
                      <td>Bonus ( % ) </td>
                      <td>{details.BonusPercent}</td>

                      <td>Penalty narration</td>
                      <td>{details.manual_adjustment_reference}</td>
                    </tr>

                    {details.bonusamount > 0 ? (
                      <tr>
                        <td>Bonus Amount </td>
                        <td>{details.bonusamount}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    ) : (
                      ""
                    )}

                    <tr>
                      <td>Total</td>
                      <td>{details.inTotalIncome}</td>
                      <td>Total Deduction</td>
                      <td>{details.inTotalDeduction}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6} style={{ fontWeight: "bold", textAlign: "right" }}>
                Net Salary =
              </Col>
              <Col span={6} style={{ fontWeight: "bold", textAlign: "center" }}>
                {details.net_salary} BDT
              </Col>
            </Row>
          </div>

          {/* Signature yo*/}
          <div>
            <br />
            <br />
            <br />
            <br />
            <Row gutter={24}>
              <Col span={6}>
                <Row gutter={24}>
                  <Col span={24}>------------------------------</Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>(Received Signature)</Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>Date:</Col>
                </Row>
              </Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6}>
                <Row gutter={24}>
                  <Col span={24}>------------------------------</Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>(Accountant Signature)</Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>Date:</Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Auth: state.auth,
    businessprofile: state.settings.businessprofile,
    brandlist: state.brand.brandlist,
  };
};

export default connect(mapStateToProps, { getAllBrand })(Payslip);
