import * as types from "../types";

const INITIAL_STATE = { brandlist: [], specificbrandlist: [] };

const tagReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE_BRAND:
      return state;

    case types.GET_ALL_BRAND:
      return { ...state, brandlist: action.payload };

    case types.GET_SPECIFIC_BRAND:
      return { ...state, specificbrandlist: action.payload };

    case types.DELETE_BRAND:
      const brandlist = state.brandlist.filter((TAG) => TAG.id !== action.payload);
      return { ...state, brandlist };

    default:
      return state;
  }
};

export default tagReducer;
