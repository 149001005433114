import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excelldownload = ({ data }) => {
  return (
    <ExcelFile
      element={
        <Button type="primary" style={{ width: "100%" }}>
          Download
        </Button>
      }
    >
      <ExcelSheet data={data} name="All Invoices">
        <ExcelColumn label="Date" value="issue_date" />
        <ExcelColumn label="Invoice No." value="invoice_number" />
        <ExcelColumn label="Order No." value="order_number" />
        <ExcelColumn label="Status" value="status" />
        <ExcelColumn label="Remarks" value="remarks" />
        <ExcelColumn label="Discount" value="discount" />
        <ExcelColumn label="Bill" value="bill" />
        <ExcelColumn label="Payment" value="payment" />
        <ExcelColumn label="Due" value="due" />
        <ExcelColumn label="VAT" value="tax" />
        <ExcelColumn label="Payment method" value="Payment_method" />
        <ExcelColumn label="Delivery date" value="delivery_date" />
        <ExcelColumn label="Delivery status" value="delivery_status" />
        <ExcelColumn label="Delivery method" value="delivery_method" />
        <ExcelColumn label="Delivery cost" value="delivery_cost" />

        <ExcelColumn label="Account no." value="Account_no" />
        <ExcelColumn label="Transection" value="Transection_no" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Excelldownload;
