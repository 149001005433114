import React, { useEffect, useState, useRef } from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const Excelldownload = ({ data, data1 }) => {
  return (
    <ExcelFile
      element={
        <Button type="primary" style={{ width: "100%" }}>
          Download
        </Button>
      }
    >
      <ExcelSheet data={data} name="Refund report">
        <ExcelColumn label="Date" value="issue_date" />
        <ExcelColumn label="Invoice No." value="invoice_number" />
        <ExcelColumn label="Purchase Method" value="in_method" />
        <ExcelColumn label="Refund Method" value="out_method" />
        <ExcelColumn label="Reason" value="reason" />
        <ExcelColumn label="Reference" value="reference" />
        <ExcelColumn label="Outlet" value="outlet" />
        <ExcelColumn label="Amount" value="amount" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default Excelldownload;
