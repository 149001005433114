import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { getServices } from "../../../actions/invoiceItem";
// import { getSpecificcontravoucheritems } from "../../../actions/accounting/ContraVoucher";
import { getBusinessProfile } from "../../../actions/settings";
import { getSpecificLocation } from "../../../actions/warehouseAction";
import dateFormat from "dateformat";

import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Button,
  Col,
  Row,
  Select,
  message,
  TreeSelect,
  Space,
  Divider,
  Drawer,
  Image,
  Skeleton,
} from "antd";

const { Option } = Select;

const Quickview = ({
  details,
  getServices,
  getBusinessProfile,
  businessprofile,
  getSpecificLocation,
  getSpecificcontravoucheritems,
}) => {
  var formatter = new Intl.NumberFormat("en-IN");
  console.log(details);
  const componentRef = useRef();
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [branch, setbranch] = useState([]);
  const count = useRef(0);
  const serialcount = useRef(0);

  const showDrawer = () => {
    getSpecificLocation(details.location).then((result) => {
      setbranch(result);
      setVisible(true);
    });
  };

  const onClose = () => {
    setVisible(false);
  };
  const renderImage = () => {

    return (
      <img
        src={branch.logo}
        style={{
          maxHeight: "60px",
        }}
      />
    );

  };
  const renderitems = () => {
    return (
      <>
        <tr style={{ height: "120px" }}>
          <td style={{ textAlign: "center" }}>{details.AccountFrom[0].name}</td>
          <td style={{ textAlign: "center" }}>{details.AccountTo[0].name}</td>
          <td style={{ textAlign: "center" }}>{details.narration}</td>
          <td style={{ textAlign: "center" }}>{details.amount}</td>
        </tr>
      </>
    );
  }



  return (
    <>
      <>
        <a href="#" onClick={showDrawer} style={{ margin: 4 }}>
          Voucher
        </a>
        <Drawer
          width="850"
          onClose={onClose}
          visible={visible}
          placement="right"
        // bodyStyle={{ paddingBottom: 80 }}
        >
          <ReactToPrint
            trigger={() => <button>Print this out!</button>}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <div
              className="invoice_print_fontSize"
              ref={componentRef}
              style={{ padding: "10px" }}
            >
              <Row
                style={{
                  borderBottom: "2px solid lightgray",
                  paddingBottom: "5px",
                }}
              >
                <Col span={16} style={{ textAlign: "left" }}>
                  <div
                    className="d-div"
                    dangerouslySetInnerHTML={{ __html: branch.address }}
                  ></div>
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  {renderImage()}
                </Col>
              </Row>
              <br></br>
              <h2 style={{ textAlign: "center" }}>CONTRA VOUCHER</h2>
              <br></br>
              <Row>
                <Col span={12}>

                </Col>

                <Col span={12} style={{ textAlign: "right" }}>
                  <Row>
                    <Col span={14}>VOUCHER NO :</Col>
                    <Col span={10}>
                      {details.voucher_number ? (
                        <b>{details.voucher_number}</b>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>CREATED BY :</Col>
                    <Col span={10}>
                      {details.Employee[0] ? details.Employee[0].name : ""}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>ISSUED DATE :</Col>
                    <Col span={10}>
                      {details.created
                        ? dateFormat(details.created, "yyyy-mm-dd")
                        : ""}
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col span={14}>REFERENCE :</Col>
                    <Col span={10}>
                      {details.referance ? details.referance : ""}
                    </Col>
                  </Row> */}
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col span={24}>
                  <table className="product_table invoice_print_fontSize">
                    <tbody>
                      <tr style={{ fontWeight: "500" }}>

                        <td>FROM</td>
                        <td>TO</td>
                        <td style={{ width: "50%" }}>
                          PARTICULAR'S DESCRIPTION
                        </td>
                        <td>AMOUNT (BDT)</td>
                      </tr>
                      {renderitems()}


                    </tbody>
                  </table>
                  {/* <Row>
                    <Col span={8} style={{ textAlign: "left" }}>
                      <br></br>
                      <h3
                        style={{
                          borderBottom: "2px solid gray",
                          display: "inline-block",
                        }}
                      >
                        TRANSACTION METHOD
                      </h3>
                      <br></br>
                      {details.Account[0] ? details.Account[0].name : ""}

                      {details.Account[0].account_no ? (
                        <>
                          <br></br>
                          Account no. {details.Account[0].account_no}
                        </>
                      ) : (
                        ""
                      )}
                      {details.Account[0].details ? (
                        <>
                          <br></br>
                          {details.Account[0].details}
                        </>
                      ) : (
                        ""
                      )}
                      {details.Account[0].address ? (
                        <>
                          <br></br>
                          Location : {details.Account[0].address}
                        </>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col
                      span={16}
                      style={{ textAlign: "right", marginTop: "5px" }}
                    >
                      <Row>
                        <Col span={14} style={{ margin: "auto" }}>
                          TOTAL AMOUNT
                        </Col>
                        <Col span={9} offset={1}>
                          <span
                            className="order_span_bill"
                            style={{ border: "1px solid" }}
                          >
                            {formatter.format(details.amount)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                </Col>
              </Row>
              <br></br>
              <br></br>
              <Row style={{ minHeight: "60px" }}>
                <Col span={8} style={{ textAlign: "left" }}></Col>
                <Col span={8} style={{ textAlign: "center" }}></Col>
                <Col span={8} style={{ textAlign: "center" }}></Col>
              </Row>
              <Row>
                <Col span={8} style={{ textAlign: "left" }}>
                  <h3
                    style={{
                      borderTop: "2px solid black",
                      display: "inline-block",
                    }}
                  >
                    ACCOUNT SIGNATURE
                  </h3>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>

                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  <h3
                    style={{
                      marginBottom: "-10px",
                      padding: "0px",
                      borderTop: "2px solid black",
                      // width: "50%",
                      display: "inline-block",
                    }}
                  >
                    AUTHORIZER SIGNATURE
                  </h3>
                </Col>
              </Row>
            </div>
          </div>
          <Row
            style={{
              borderBottom: "2px solid lightgray",
              paddingBottom: "5px",
            }}
          >
            <Col span={16} style={{ textAlign: "left" }}>
              <div
                className="d-div"
                dangerouslySetInnerHTML={{ __html: branch.address }}
              ></div>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              {renderImage()}
            </Col>
          </Row>
          <br></br>
          <h2 style={{ textAlign: "center" }}>CONTRA VOUCHER</h2>
          <br></br>
          <Row>
            <Col span={12}></Col>

            <Col span={12} style={{ textAlign: "right" }}>
              <Row>
                <Col span={14}>VOUCHER NO :</Col>
                <Col span={10}>
                  {details.voucher_number ? (
                    <b>{details.voucher_number}</b>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={14}>CREATED BY :</Col>
                <Col span={10}>
                  {details.Employee[0] ? details.Employee[0].name : ""}
                </Col>
              </Row>
              <Row>
                <Col span={14}>ISSUED DATE :</Col>
                <Col span={10}>
                  {details.created
                    ? dateFormat(details.created, "yyyy-mm-dd")
                    : ""}
                </Col>
              </Row>
              {/* <Row>
                <Col span={14}>REFERENCE :</Col>
                <Col span={10}>
                  {details.referance ? details.referance : ""}
                </Col>
              </Row> */}
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col span={24}>
              <table className="product_table">
                <tbody>
                  <tr style={{ fontWeight: "500" }}>
                    <td>FROM</td>
                    <td>TO</td>
                    <td style={{ width: "50%" }}>
                      PARTICULAR'S DESCRIPTION
                    </td>
                    <td>AMOUNT (BDT)</td>
                  </tr>
                  {renderitems()}


                </tbody>
              </table>

            </Col>
          </Row>
          <br></br>
          <br></br>
          <Row style={{ minHeight: "60px" }}>
            <Col span={8} style={{ textAlign: "left" }}></Col>
            <Col span={8} style={{ textAlign: "center" }}></Col>
            <Col span={8} style={{ textAlign: "center" }}></Col>
          </Row>
          <Row>
            <Col span={8} style={{ textAlign: "left" }}>
              <h3
                style={{
                  borderTop: "2px solid black",
                  display: "inline-block",
                }}
              >
                ACCOUNT SIGNATURE
              </h3>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>

            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <h3
                style={{
                  marginBottom: "-10px",
                  padding: "0px",
                  borderTop: "2px solid black",

                  display: "inline-block",
                }}
              >
                AUTHORIZER SIGNATURE
              </h3>
            </Col>
          </Row>
        </Drawer>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
  };
};

export default connect(mapStateToProps, {
  getBusinessProfile,
  getSpecificLocation,

})(Quickview);